<template>
    <div>
        <div class="das_top_cont">
            <div class="history_box">
                <div class="title flex_center_between_box">
                    <div class="title_txt flex_center_start_box">
                        <img src="../../assets/img/account/ment.png" />
                        Access Management
                    </div>
                    <div class="c_d_buttom flex_box">
                        <button class="btn_blue flex_center" @click="searchHistory">
                            <img src="../../assets/img/account/jia (2).png" />
                            Add
                        </button>
                    </div>
                </div>
            </div>
            <div class="top_tab">
                <el-tabs v-model="tabActiveName" type="card" class="tab_box" @tab-click="handleTabClick">
                    <el-tab-pane label="User Groups" name="UserGroups" class="result_tab">
                        <div class="table_box">
                            <el-table ref="OrderList" :data="OrderList" border style="width: 100%">
                                <!-- 空数据状态的插槽 -->
                                <template slot="empty">No Data</template>
                                <el-table-column label="No." type="index" align="center" show-overflow-tooltip :resizable="false" />
                                <el-table-column label="Group name" prop="name" align="left" show-overflow-tooltip :resizable="false" />
                                <el-table-column label="Parent group name" prop="pid" align="left" show-overflow-tooltip :resizable="false" width="200" />
                                <el-table-column label="Organization" prop="organization" align="left" show-overflow-tooltip :resizable="false" class-name="company_link">
                                    <template slot-scope="scope">
                                        <div class="ellipsis">{{ scope.row.organization }}</div>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Number of groups" prop="deptNumber" align="left" show-overflow-tooltip :resizable="false" />
                                <el-table-column label="Group status" align="left" show-overflow-tooltip :resizable="false">
                                    <template slot-scope="scope">
                                        <div v-if="scope.row.status" class="ellipsis">Valid</div>
                                        <div v-else class="ellipsis">Invalid</div>
                                    </template>
                                </el-table-column>

                                <el-table-column label="" align="center" show-overflow-tooltip :resizable="false" class-name="company_link">
                                    <template slot-scope="scope">
                                        <div class="flex_center">
                                            <el-tooltip class="tbl_opr_tip" :visible-arrow="false" effect="light" content="Edit" placement="top">
                                                <div class="oper_down" @click="groupsDown(scope.row)">
                                                    <img src="../../assets/img/account/bianji.png" />
                                                </div>
                                            </el-tooltip>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <div class="page_i_box">
                                <Page :totalFont="true" :total="page.total" @onPageChange="onPageChange" @currtentPageChange="currtentPageChange"></Page>
                                <div class="search_rsult_txt line_2">{{ page.pageNo }}-{{ page.pageSize }} of over {{ page.total | FilterNum}} results</div>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="User" name="User" class="result_tab">
                        <div class="table_box">
                            <el-table ref="UsageList" :data="UsageList" border style="width: 100%">
                                <!-- 空数据状态的插槽 -->
                                <template slot="empty">No Data</template>
                                <el-table-column label="No." type="index" align="center" show-overflow-tooltip :resizable="false" />
                                <el-table-column label="Account" prop="email" align="left" show-overflow-tooltip :resizable="false" class-name="company_link"></el-table-column>
                                <el-table-column label="Group name" prop="groupName" align="left" show-overflow-tooltip :resizable="false" />
                                <el-table-column label="Role" prop="roleName" align="left" show-overflow-tooltip :resizable="false" />
                                <el-table-column label="Account status" align="left" show-overflow-tooltip :resizable="false">
                                    <template slot-scope="scope">
                                        <div  v-if="scope.row.enable" class="ellipsis">Valid </div>
                                        <div v-else >Invalid</div>
                                    </template>
                                </el-table-column>
                                <el-table-column label="" align="center" show-overflow-tooltip :resizable="false" class-name="company_link">
                                    <template slot-scope="scope">
                                        <div class="flex_center">
                                            <el-tooltip class="tbl_opr_tip" :visible-arrow="false" effect="light" content="Edit" placement="top">
                                                <div class="oper_down" @click="hanldeDown(scope.row)">
                                                    <img src="../../assets/img/account/bianji.png" />
                                                </div>
                                            </el-tooltip>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <div class="page_i_box">
                                <Page :totalFont="true" :total="total1" @onPageChange="onPageChange" @currtentPageChange="currtentPageChange"></Page>
                                <div class="search_rsult_txt line_2">{{ page.pageNo }}-{{ page.pageSize }} of over {{ total1 | FilterNum}} results</div>
                            </div>
                        </div>
                    </el-tab-pane>

                    <el-tab-pane label="Roles" name="Roles" class="result_tab">
                        <div class="table_box">
                            <el-table ref="loginDurationAndTimes" :data="loginDurationAndTimes" border style="width: 100%">
                                <!-- 空数据状态的插槽 -->
                                <template slot="empty">No Data</template>
                                <el-table-column label="No." type="index" align="center" show-overflow-tooltip :resizable="false" />

                                <el-table-column label="Role name" prop="name" align="left" show-overflow-tooltip :resizable="false" />
                                <el-table-column label="Position" prop="memo" align="center" show-overflow-tooltip :resizable="false" />
                                <el-table-column label="Role status" align="center" show-overflow-tooltip :resizable="false">
                                    <template slot-scope="scope">
                                        <div v-if="scope.row.status == '0'" class="ellipsis">Valid</div>
                                        <div v-else>Invalid</div>
                                    </template>
                                </el-table-column>
                                <el-table-column label="" align="center" show-overflow-tooltip :resizable="false">
                                    <template slot-scope="scope">
                                        <div class="flex_center">
                                            <el-tooltip class="tbl_opr_tip" :visible-arrow="false" effect="light" content="Edit" placement="top">
                                                <div class="oper_down" @click="hanldeDetails(scope.row)">
                                                    <img src="../../assets/img/account/bianji.png" />
                                                </div>
                                            </el-tooltip>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <div class="page_i_box">
                                <Page :totalFont="true" :total="total2" @onPageChange="onPageChange" @currtentPageChange="currtentPageChange"></Page>
                                <div class="search_rsult_txt line_2">{{ page.pageNo }}-{{ page.pageSize }} of over {{ total2 | FilterNum}} results</div>
                            </div>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>

        <!-- New Group 弹框 -->
        <el-dialog :visible.sync="timesDetailDialog" center :close-on-click-modal="false" custom-class="dialog_normal" :append-to-body="true">
            <div class="detail_cont">
                <div class="popTitle">New Group</div>
                <div class="detail_pop_list">
                    <el-form ref="form" :model="form" label-width="80px">
                        <el-form-item label="Group Name:">
                            <el-input v-model="form.name"></el-input>
                        </el-form-item>
                        <el-form-item label="Parent Group Name:">
                            <el-cascader placeholder="Parent Group Name:" collapse-tags v-model="form.region" :options="productOptions" :props="optionsProps" :show-all-levels="false" popper-class="head_cas" @change="handleChange"></el-cascader>
                        </el-form-item>
                        <el-form-item label="Organization:">
                            <el-input v-model="form.Organization"></el-input>
                        </el-form-item>
                        <el-form-item label="Number of groups:">
                            <el-input v-model="form.groups"></el-input>
                        </el-form-item>
                        <el-form-item label="Group Status:">
                            <el-radio-group v-model="form.resource">
                                <el-radio :label="true">Valid</el-radio>
                                <el-radio :label="false">Invalid</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-form>

                    <div class="txt_right">
                        <button class="btn_export" type="primary" @click="onSubmit">Confirm</button>
                        <button class="btn_export" @click="quxiao">Cancel</button>
                    </div>
                </div>
            </div>
        </el-dialog>

        <!-- New User 弹框 -->
        <el-dialog :visible.sync="timesDetailDialog2" center :close-on-click-modal="false" custom-class="dialog_normal" :append-to-body="true">
            <div class="detail_cont">
                <div class="popTitle">New User</div>
                <div class="detail_pop_list">
                    <el-form ref="form" :model="form" label-width="80px">
                        <el-form-item label="Account:">
                            <el-input v-model="form.username"></el-input>
                        </el-form-item>
                        <el-form-item label="Group Name:">
                            <el-select v-model="form.userGroup" placeholder="">
                                <el-option :label="item.name" :value="item.id" v-for="(item, index) in OrderList" :key="index"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="Role:">
                            <el-select v-model="form.userrole" placeholder="">
                                <el-option :label="item.name" :value="item.id" v-for="(item, index) in loginDurationAndTimes" :key="index"></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="Account Status:">
                            <el-radio-group v-model="form.usertart">
                                <el-radio :label="true"> Valid</el-radio>
                                <el-radio :label="false">Invalid</el-radio>
                               
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item></el-form-item>
                    </el-form>
                </div>
                <div class="txt_right">
                    <button class="btn_export" type="primary" @click="onSubmit">Confirm</button>
                    <button class="btn_export" @click="quxiao">Cancel</button>
                </div>
            </div>
        </el-dialog>
        <!-- New Role 弹框 -->
        <el-dialog :visible.sync="timesDetailDialog3" center :close-on-click-modal="false" custom-class="dialog_normal" :append-to-body="true">
            <div class="detail_cont">
                <div class="popTitle">New Role</div>
                <div class="detail_pop_list">
                    <el-form ref="form" :model="form" label-width="80px">
                        <el-form-item label="Role Name:">
                            <el-input v-model="form.rolename"></el-input>
                        </el-form-item>
                        <el-form-item label="Position:">
                            <el-input v-model="form.rolepostion"></el-input>
                        </el-form-item>

                        <el-form-item label="Role Status:">
                            <el-radio-group v-model="form.rolestart">
                                <el-radio label="0">Valid</el-radio>
                                <el-radio label="1">Invalid</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <!-- "Permission Settings: -->
                        <el-form-item label="Permission Settings:"></el-form-item>
                        <div class="switchlist">
                            <!-- <el-form-item label="Dashboard">
                                <el-switch v-model="form.delivery" active-color="#13ce66"></el-switch>
                            </el-form-item>
                            <el-form-item label="Add Credit">
                                <el-switch v-model="form.delivery" active-color="#13ce66"></el-switch>
                            </el-form-item>
                            <el-form-item label="My Database">
                                <el-switch v-model="form.delivery" active-color="#13ce66"></el-switch>
                            </el-form-item> -->
                            <div class="witch-fater" v-for="(item, index) in newrolelist" :key="index">
                                <el-form-item :label="item.name">
                                    <el-switch v-model="item.type" active-value="1" inactive-value="0" active-color="#13ce66" @change="SwitchChange(item.id)"></el-switch>
                                </el-form-item>
                                <div style="padding-left: 20px" v-for="(ite, ind) in item.list" :key="ind">
                                    <el-form-item :label="ite.name">
                                        <el-switch style="padding-left: -20px" active-value="1" inactive-value="0" v-model="ite.type" @change="SwitchChange(ite.id)" active-color="#13ce66"></el-switch>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>
                    </el-form>
                </div>
                <div class="txt_right">
                    <button class="btn_export" type="primary" @click="onSubmit">Confirm</button>
                    <button class="btn_export" @click="quxiao">Cancel</button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import Page from '@/components/page';
import { getGroupList, getUserList, getRoleById, editSonUserInfo, editRoleInfo, getRoleList, getaddGroup, getGroupTreeList, addSonUserInfo, addRole, getPermissionList } from '../../api/userApi';
import { mixins1 } from '@/mixins/index'
export default {
    components: { Page },
    mixins: [mixins1],
    data() {
        return {
            id: '',
            select1: [],
            code: '',
            productOptions: [],
            form: {
                name: '',
                Organization: '',
                region: '',
                groups: '',
                resource: true,
                rolename: '',
                rolepostion: '',

                userrole: '',
                userGroup: '',
                username: '',
                delivery: false,
                usertart: false,
                rolestart: '0',
                type: true,
            },
            optionsProps: {
                label: 'name', //名称展示
                value: 'id', //值展示
                multiple: true,
                checkStrictly: true,
                // checkStrictly: true, //是否严格的遵守父子节点不互相关联
                expandTrigger: 'click', //次级菜单的展开方式
                emitPath: false,
            },
            // optionsProps: {
            //     label: 'nameEn', //名称展示
            //     value: 'id', //值展示
            //     // checkStrictly: true, //是否严格的遵守父子节点不互相关联
            //     expandTrigger: 'click', //次级菜单的展开方式
            //     emitPath: false,
            // },
            newrolelist: [],
            timesDetailDialog: false, //List of login duration and times 弹框
            timesDetailDialog2: false,
            timesDetailDialog3: false,
            // 区分是编辑 还是提交
            qufen: false,
            select: [],
            tabActiveName: 'UserGroups',
            quanxinlist: [],
            jiaoseid: '',
            total1: 0,
            total2: 0,
            UsageList: [
                {
                    UserName: '111@qq.com',
                    Date: '2022-11-14',
                    Subject: '22',
                    Type: 'viewed',
                },
            ],
            page: {
                pageNo: 1,
                pageSize: 10,
                total: 0,
            },
            OrderList: [],

            RechargeList: [
                {
                    Date: '2022-11-14',
                    User: 'zhangsan',
                    PaymentAmount: '$1,000.00',
                    InvoiceRequest: 1,
                },
            ],

            loginDurationAndTimes: [],
        };
    },
    mounted() {
        this.getportfoillist();
    },

    methods: {
        handleChange(value) {},
        //tab切换
        async handleTabClick() {
            this.getportfoillist();
        },
        async getportfoillist() {
            const params = {
                pageNum: this.page.pageNo,
                pageSize: this.page.pageSize,
            };
            // user rodes
            if (this.tabActiveName == 'UserGroups') {
                let res = await getGroupList(params);
                if (res.code) {
                    this.OrderList = res.data.rows;

                    // console.log(this.OrderList ,'Orderlist');
                    this.OrderList.map((item, index) => {
                        this.OrderList.map(ite => {
                            if (ite.id == item.pid) {
                                item.pid = ite.name;
                            }
                        });
                    });
                    // console.log(this.OrderList, '1list');
                    this.page.total = res.data.total;
                    this.IndicatorsOptions = res.data.rows;
                } else {
                    this.UsageList = [];
                }
            }

            if (this.tabActiveName == 'User') {
                let user = await getUserList(params);
                if (user.code) {
                    this.UsageList = user.data.rows;
                    this.total1 = user.data.total;
                    // console.log(user.data.rows,'user',this.total1);
                } else {
                    this.UsageList = [];
                }
                let role = await getRoleList(params);
                if (role.code) {
                    this.total2 = role.data.total;
                    this.loginDurationAndTimes = role.data.rows;
                } else {
                    this.loginDurationAndTimes = [];
                }
       
            }
            if (this.tabActiveName == 'Roles') {
                let role = await getRoleList(params);
                if (role.code) {
                    this.total2 = role.data.total;
                    this.loginDurationAndTimes = role.data.rows;
                } else {
                    this.loginDurationAndTimes = [];
                }
            }
        },
        // 角色添加
        async addRolename() {
            let list = [];
            const pa = {
                name: this.form.rolename,
                memo: this.form.rolepostion,
                sysRoleMenuIdList: this.quanxinlist,
                status: this.form.rolestart,
            };

            let addr = await addRole(pa);

            if (addr && addr.code) {
                // this.getprolist()
                this.$message({
                    message: addr.msg,
                    center: true,
                    type: 'success',
                });
                this.timesDetailDialog3 = false;
                this.getportfoillist();
            } else {
                this.$message({
                    message: addr.msg,
                    center: true,
                    type: 'error',
                });
            }
        },
        // 脚色编辑
        async editRole(userCode) {
            const pa = {
                id: this.jiaoseid,
                userCode: userCode,
                name: this.form.rolename,
                memo: this.form.rolepostion,
                sysRoleMenuIdList: this.quanxinlist,
                status: this.form.rolestart,
            };
            let edit = await editRoleInfo(pa);
            if (edit && edit.code) {
                // this.getprolist()
                this.$message({
                    message: edit.msg,
                    center: true,
                    type: 'success',
                });
                // this.getprolist();
                this.timesDetailDialog3 = false;
                this.getportfoillist();
            } else {
                this.$message({
                    message: edit.msg,
                    center: true,
                    type: 'error',
                });
            }
        },
        async getprolist() {
            let permis = await getPermissionList();

            if (permis.code) {
                this.newrolelist = permis.data;
            } else {
                this.newrolelist = [];
            }
        },

        //分页
        onPageChange(pageNo) {
            this.page.pageNo = pageNo;
            this.getportfoillist();
        },
        currtentPageChange(pageSize) {
            this.page.pageSize = pageSize;
            this.getportfoillist();
        },

        //Order List  User编辑
        hanldeDown(row) {
            // console.log(row);
            this.form.username = row.email;
            this.code = row.userCode;
            this.form.usertart = row.enable;
            this.form.userrole=row.roleName
            this.form.userGroup=row.groupName
            this.qufen = true;
            this.timesDetailDialog2 = true;
        },
        //Recharge list
        hanldeApply(row) {},
        //List of login duration and times
        // Roles编辑
        async hanldeDetails(row) {
            this.jiaoseid = row.id;
            let params = 'id=' + row.id;
            getRoleById(params).then(res => {
                if (res.code) {
                    // rolename: '',
                    // rolepostion: '',
                    // resource: false,
                    // Organization: '',
                    // groups: '',
                    // userrole:'',
                    // userGroup:'',
                    // username:'',
                    // delivery: false,
                    // usertart:false,
                    // rolestart: '0'
                    let Role = res.data;
                    this.form.rolename = Role.name;
                    this.form.rolepostion = Role.memo;
                    this.form.rolestart = Role.status;
                    this.newrolelist = Role.sysMenuList;
                }
            });
            this.timesDetailDialog3 = true;
            // 获取开关
            this.getprolist();

            this.qufen = true;
        },
        // groups编辑
        groupsDown(scope) {
            // this.timesDetailDialog = true;
            this.id = scope.id;
            this.qufen = false;
            this.searchHistory();
            this.form.name = scope.name;
            this.form.Organization = scope.organization;
            this.form.groups = scope.deptNumber;
            (this.form.resource = scope.status), (this.form.region = '');
        },
        SwitchChange(event) {
            this.quanxinlist=[]
            // console.log( this.newrolelist);
             this.newrolelist.map(item=>{
                // console.log(`item`, item);
                if(item.type=='1'){
                    if(item.type=='1'){
                           var index = this.quanxinlist.indexOf(item.id);
            if (this.quanxinlist.indexOf(item.id) === -1) {
                this.quanxinlist.push(item.id);
            } else {
                this.quanxinlist.splice(index, 1);
            }
                  
                }
                    // this.quanxinlist.push(item.id)
                }
                item.list.map(it=>{
                    if(it.type=='1'){
                           var index = this.quanxinlist.indexOf(it.id);
            if (this.quanxinlist.indexOf(it.id) === -1) {
                this.quanxinlist.push(it.id);
            } else {
                this.quanxinlist.splice(index, 1);
            }
                  
                }
                })
             })

            // var index = this.quanxinlist.indexOf(event);
            // if (this.quanxinlist.indexOf(event) === -1) {
            //     this.quanxinlist.push(event);
            // } else {
            //     this.quanxinlist.splice(index, 1);
            // }
        },

        ResetHistory() {
            this.showEchart = false;
        },
        // add+
        async searchHistory() {
            if (this.tabActiveName == 'UserGroups') {
                this.form.name = '';
                this.form.Organization = '';
                this.form.groups = '';
                (this.form.resource = false), (this.form.region = '');
                this.timesDetailDialog = true;
                // 获取下拉
                let tree = await getGroupTreeList();
                if (tree.code) {
                    // this.select = tree.data
                    // 转换替换数据
                    this.productOptions = JSON.parse(JSON.stringify(tree.data).replace(/list/g, 'children'));
                }
            }
            if (this.tabActiveName == 'User') {
                this.form.username = '';
                this.form.userGroup = '';
                this.form.userrole = '';
                this.form.usertart = false;
                this.qufen = false;
                this.timesDetailDialog2 = true;
            }
            if (this.tabActiveName == 'Roles') {
                this.form.rolename = '';
                this.form.rolepostion = '';
                this.form.rolestart = '';
                this.newrolelist = '';
                this.timesDetailDialog3 = true;
                this.qufen = false;
                this.getprolist();
            }
        },
        async onSubmit() {

            const userCode = JSON.parse(sessionStorage.getItem('userInfo'))==null?JSON.parse(localStorage.getItem('userInfo')).userInfo.userCode:JSON.parse(sessionStorage.getItem('userInfo')).userInfo.userCode
            let par = {
                id: this.id,
                name: this.form.name,
                pid: this.form.region,
                organization: this.form.Organization,
                status: this.form.resource,
                deptNumber: this.form.groups,
                userCode: userCode,
            };
            if (this.tabActiveName == 'UserGroups') {
                getaddGroup(par).then(res => {
                    if (res.code) {
                        this.$message({
                            message: res.msg,
                            center: true,
                            type: 'success',
                        });
                        this.getportfoillist();
                    } else {
                        this.$message({
                            message: res.msg,
                            center: true,
                            type: 'false',
                        });
                    }
                });
                this.timesDetailDialog = false;
            }
            if (this.tabActiveName == 'User') {
                this.OrderList.map(item=>{
                  if(item.name==this.form.userGroup){
                    this.form.userGroup=item.id
                  }
                })
                this.loginDurationAndTimes.map(item=>{
                  if(item.name==this.form.userrole){
                    // console.log(this.form.userrole);
                    this.form.userrole=item.id
                  }
                })
                let user = {
                    loginName: this.form.username,
                    enable: this.form.usertart,
                    roleId: this.form.userrole,
                    groupId: this.form.userGroup,
                };
                if (this.qufen) {
                    await editSonUserInfo({ ...user, userCode: this.code }).then(res => {
                        if (res.code) {
                            this.$message({
                                message: res.msg,
                                center: true,
                                type: 'success',
                            });
                            this.getportfoillist();
                            this.timesDetailDialog2 = false;
                        } else {
                            this.$message({
                                message: res.msg,
                                center: true,
                                type: 'false',
                            });
                        }
                    });
                } else {
                    await addSonUserInfo(user).then(res => {
                        if (res.code) {
                            this.$message({
                                message: res.msg,
                                center: true,
                                type: 'success',
                            });
                            this.getportfoillist();
                            this.timesDetailDialog2 = false;
                        } else {
                            this.$message({
                                message: res.msg,
                                center: true,
                                type: 'false',
                            });
                        }
                    });
                }
            }
            if (this.tabActiveName == 'Roles') {
                if (this.qufen) {
                    this.editRole(userCode);
                } else {
                    this.addRolename();
                }
            }
        },
        quxiao() {
            this.timesDetailDialog3 = false;
            this.timesDetailDialog2 = false;
            this.timesDetailDialog = false;
        },
    },
};
</script>
<style scoped lang="less">
/* 日历大小 */
.el-range-editor.el-input__inner {
    width: 224px !important;
    padding: 3px 3px;
}

.el-date-editor /deep/ .el-range__icon {
    font-size: 14px;
    margin-left: -5px;
    color: #c0c4cc;
    /* float: left; */
    line-height: 32px;
    position: relative;
    left: 47%;
}

.cont_m {
    margin-top: 30px;
    margin-bottom: 80px;
}

.country_box {
    margin-top: 60px;
}

.country_box .title {
    margin-bottom: 0;
}

.history_box {
    // margin-top: 22px;
}

.title {
    margin-bottom: 20px;
}

.title_txt {
    font-size: 16px;
    font-family: 'Arial Bold';
}

.title_txt img {
    width: 20px;
    margin-right: 8px;
}

.c_d_buttom {
    justify-content: flex-end;
    margin-top: 20px;
    margin-bottom: 0;
}

.btn_blue {
    width: 140px;
    height: 40px;
    background: #1290c9;
    border-radius: 12px;
    font-size: 16px;
    color: #ffffff;
    cursor: pointer;
}

.btn_blu {
    margin-right: 20px;
}

.btn_blue img {
    width: 18px;
    height: 18px;
    margin-right: 6px;
}

.his_sel_box .sel_box /deep/ .el-input__inner {
    border-color: #8497ab;
    border-radius: 8px;
}

.his_sel_box .sel_box /deep/ .el-select .el-icon-arrow-up:before {
    color: #8497ab;
    font-weight: 500;
}

.top_tab {
    margin-top: 40px;
}

.tab_box /deep/ .el-tabs__header {
    margin-bottom: 10px;
}

.el-tabs--card > .el-tabs__header {
    border: none;
}

.tab_box /deep/ .el-tabs__header,
.tab_box /deep/.el-tabs__content {
    width: 100%;
    box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
    border-radius: 14px;
}

.tab_box /deep/ .el-tabs__header {
    padding: 11px 75px;
}

.result_tab {
    padding: 20px;
}

.tab_box /deep/ .el-tabs__nav-wrap {
    width: 690px;
    margin: auto;
    height: 34px;
    background: #f2f5f9;
    border-radius: 8px;
    border: 1px solid #e7eaed;
}

.tab_box /deep/ .el-tabs__nav {
    width: 100%;
    border: none;
    padding: 6px 12px;
    display: flex;
    justify-content: space-around;
}

.tab_box /deep/ .el-tabs__item {
    /* padding: 0 5px; */
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    color: #8497ab;
    width: 120px;

    border: none;
    text-align: center;
}

.tab_box /deep/ .el-tabs__item:last-child {
    padding-right: 15px !important;
}

.tab_box /deep/ .el-tabs__item.is-active {
    width: 150px;
    background: #ff7600;
    box-shadow: 0px 1px 2px 0px #c44306;
    border-radius: 4px;
    font-size: 14px;
    font-family: 'Arial Bold';
    color: #ffffff;
}

.tab_box /deep/ .el-table--scrollable-x .el-table__body-wrapper {
    overflow: hidden;
}

.tab_box /deep/ .el-table__body,
.tab_box /deep/ .el-table__footer,
.tab_box /deep/ .el-table__header {
    width: auto !important;
}

.detail_pop_list {
    margin: 40px auto 0;
}

.detail_pop_item {
    border-bottom: 1px solid #e7eaed;
    padding: 15px 0;
}

.detail_pop_txt,
.detail_pop_txt {
    color: #022955;
}

.txt_right {
    //   margin-left: -150px;
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}

.popTitle {
    height: 24px;
    font-size: 20px;
}

/deep/.el-form-item__label {
    width: 150px !important;
    text-align: left;
}

.witch-fater /deep/.el-form-item__label {
    width: 210px !important;
    text-align: left;
}

/deep/.el-form-item__content {
    line-height: 40px;
    position: relative;
    font-size: 14px;

    margin-left: 150px !important;
}

/deep/.el-form-item__label {
    color: #022955ff;
}

.detail_cont {
    /deep/.el-select {
        display: inline-block;
        position: relative;
        width: 100%;
    }
}

.switchlist {
    position: relative;
    top: -20px;
    background: #f2f5f9;
    border-radius: 8px;
    border: 1px solid #e7eaed;
    padding: 10px;

    .el-form-item {
        margin-bottom: 22px;
        padding-left: 10px;
        padding-right: 10px;
    }

    /deep/.el-form-item:hover {
        background: rgba(18, 144, 201, 0.09);
        border-radius: 8px;
        color: #1290c9ff;

        .el-form-item__label {
            color: #1290c9ff;
        }
    }

    /deep/.el-form-item__content {
        text-align: right;
    }
}
.table_box /deep/ .el-table th > .cell:first-child {
    padding-left: 0 !important;
}
</style>